import smoothscroll from 'smoothscroll-polyfill'

export class SmoothScrollList {
  constructor() {
		smoothscroll.polyfill();
    this.smoothScrollList = document.querySelectorAll('.js-smoothscroll');

    this.smoothScrollList.forEach((element) => {
      new SmoothScroll(element);
    });
  }
}

class SmoothScroll {
	constructor(self) {
		this.self = self;
		this.target = document.querySelector(this.self.getAttribute('href'));

		this.self.addEventListener('click', (e) => {
			e.preventDefault();
			this.target.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			})
		})
	}
}
