export class Responsive {
	constructor() {
		this.footer = {};
		//this.footer.target = document.querySelectorAll('.js-responsive-target');
		this.footer.containerMd = document.querySelector('.js-responsive-container-md');
		this.footer.containerSm1 = document.querySelector('.js-responsive-container-sm-1');
		this.footer.containerSm2 = document.querySelector('.js-responsive-container-sm-2');

		window.addEventListener('resize', (e) => {
			if(window.innerWidth >= 900) {
				if(this.footer.containerMd.children.length > 0) return;
				this.getTarget().forEach((node) => {
					this.footer.containerMd.insertBefore(node, null);
				})
			} else {
				if(this.footer.containerSm1.children.length > 0) return;
				this.getTarget().forEach((node, index) => {
					if(index <= 1) {
						this.footer.containerSm1.insertBefore(node, null);
					} else {
						this.footer.containerSm2.insertBefore(node, null);
					}
				})
			}
		});

		const event = document.createEvent('Event');
		event.initEvent('resize', false, true);
		window.dispatchEvent(event);
	}

	getTarget() {
		return document.querySelectorAll('.js-responsive-target');
	}
}
