import Swiper from 'swiper';

export class Carousel {
  constructor() {
    this.video = document.querySelector('.js-video');
		if(!this.video) return;
		this.options = {
			 effect: 'fade',
			 loop: true,
			 //autoplay: {
			 //   delay: 17000,
			 //   stopOnLastSlide: false,
			 //},
			 pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true
			 }
		}
		this.swiper = new Swiper('#swiper01', this.options);

		this.video.addEventListener('ended', (e) => {
			 this.swiper.slideNext(1000);
		});

		this.swiper.on('slideChange', (e) => {
			 if(this.swiper.activeIndex === 1) {
					this.play();
			 } else {
					if(this.swiper.activeIndex === 2) {
						 window.setTimeout(() => {
								this.swiper.slideTo(1, 1000);
						 }, 4000)
					} else {
						 window.setTimeout(() => {
								this.swiper.slideNext(1000);
						 }, 4000)
					}
			 }
		})
  }

	async play() {
   try {
      await this.video.play();
   } catch(err) {
      console.log('error', err);
   }
	}
}