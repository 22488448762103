import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { Menu } from './partial/Menu';
import { Carousel } from './partial/Carousel'
import { Mailmagazine } from './partial/Mailmagazine';
import { AccordionList } from './partial/Accordion';
import { Responsive } from './partial/Responsive';
import { Slider } from './partial/Slider';
//import { Header } from './partial/Header';
import { MegaDropdown } from './partial/MegaDropdown';
import { SmoothScrollList } from './partial/SmoothScroll';
import { Modal } from './partial/Modal';
import { Contact } from './partial/Contact';
import Stickyfill from 'stickyfilljs';

// polyfill stickey for IE11
if (!Element.prototype.closest) {
   const stickys = document.querySelectorAll('.js-sticky');
   Stickyfill.add(stickys);
}

// plyfill closest for IE+9
if (!Element.prototype.matches) {
   Element.prototype.matches = Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
   Element.prototype.closest = function (s) {
      var el = this;

      do {
         if (Element.prototype.matches.call(el, s)) return el;
         el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
   };
}

new Menu();
new Carousel();
new Mailmagazine();
new AccordionList();
new Responsive();
new Slider();
//new Header();
new MegaDropdown();
new SmoothScrollList();
new Modal();
new Contact();