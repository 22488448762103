export class MegaDropdown {
  constructor() {
		this.body = document.querySelector('body')
		this.svgLayer = null;

		const trigger = document.querySelectorAll('.js-header-trigger')
		trigger.forEach((element) => {
			new SVG(element)
		})
  }
}

class SVG {
	constructor(trigger) {
		this.trigger = trigger
		this.menu = this.trigger.querySelector('.menu-list, .search-form')
		if(!this.menu) return

		this.header = document.querySelector('.js-header')
		this.body = document.querySelector('body')
		this.svg = this.create()
		this.bindManipulate = this.manipulate.bind(this)
		this.inserted = false

		this.trigger.addEventListener('mouseenter', () => {
			if(!this.inserted){
				this.insert()
				this.body.addEventListener('mousemove', this.bindManipulate)
			}
		})

	}

	create() {
		const trigger = this.trigger.getBoundingClientRect()
		const menu = this.menu.getBoundingClientRect()

		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
		svg.setAttribute('class', 'dropdown-layer')
		svg.setAttribute('height', trigger.height + menu.height)
		const triggerRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
		triggerRect.setAttribute('class', 'trigger')
		triggerRect.setAttribute('fill', 'none')
		triggerRect.setAttribute('x', trigger.x)
		triggerRect.setAttribute('width', trigger.width)
		triggerRect.setAttribute('height', trigger.height)
		const menuRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
		menuRect.setAttribute('class', 'menu')
		menuRect.setAttribute('fill', 'none')
		menuRect.setAttribute('y', menu.y)
		menuRect.setAttribute('height', menu.height)
		const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
		const height = 68.22// なぜか正しい値が取れないので固定値を入れる
		const adjustment = 1// なぜか微妙な隙間が開くので調整
		path.setAttribute('fill', 'none')
		//path.setAttribute('visibility', 'visible')
		path.setAttribute('d', `
			M ${trigger.x} ${trigger.y + height / 2}
			Q ${trigger.x} ${menu.y},
				${menu.x} ${menu.y}
			h ${menu.width}
			Q ${trigger.x + trigger.width - adjustment} ${menu.y},
				${trigger.x + trigger.width - adjustment} ${trigger.y + height / 2}
			v ${height / 2}
			h ${-trigger.width + adjustment}
			z
		`)
		/*
			M ${trigger.x} ${trigger.y + height / 2}
			Q ${trigger.x} ${menu.y},
				${menu.x} ${menu.y}
			h ${menu.width}
			Q ${trigger.x + trigger.width - adjustment} ${menu.y},
				${trigger.x + trigger.width - adjustment} ${trigger.y + height / 2}
			v ${height / 2}
			h ${-trigger.width + adjustment}
			z
		*/
			//v ${trigger.height}

		svg.appendChild(triggerRect)
		svg.appendChild(menuRect)
		svg.appendChild(path)

		return svg
	}

	insert() {
		this.inserted = true
		this.body.appendChild(this.svg)
	}

	remove() {
		this.inserted = false
		this.body.removeChild(this.svg)
	}

	manipulate(e) {
		const isPath = e.target.nodeName.toLowerCase() === 'path'
		const isTrigger = e.target.classList.contains('.js-header-trigger')
		const isTriggerChild = e.target.closest('.js-header-trigger') === this.trigger
		if(isPath || isTrigger || isTriggerChild) {
			if(!this.trigger.classList.contains('_active')) {
				this.trigger.classList.add('_active')
			}
			if(!this.header.classList.contains('_hover')) {
				this.header.classList.add('_hover')
			}
		} else {
			this.trigger.classList.remove('_active')
			this.header.classList.remove('_hover')
			this.body.removeEventListener('mousemove', this.bindManipulate)
			this.remove()
		}
	}
}
