export class Menu {
	constructor() {
		this.body = document.querySelector('body')
		this.menu = document.querySelector('.js-menu')
		this.trigger = document.querySelector('.js-menu-trigger')

		this.trigger.addEventListener('click', (e) => {
			this.body.classList.toggle('fixed')
			this.menu.classList.toggle('active')
		})
	}
}