// TODO: import errorを解決する
//import {breakPoint} from 'Var.js';
const breakPoint = 900;
export class AccordionList {
  constructor() {
    this.accordionList = document.querySelectorAll('.js-accordion');

    this.accordionList.forEach((element) => {
      new Accordion(element);
    });
  }
}

class Accordion {
	constructor(self) {
		const triggerName = 'js-accordion-trigger';
		this.self = self;
		this.trigger = this.self.querySelectorAll(`.${triggerName}`);
		this.content = this.self.querySelectorAll('.js-accordion-target');

		this.self.addEventListener('click', (e) => {
			if(!e.target.classList.contains(triggerName)) return;
			if(!/\/newsevent\/$/.test(e.target.href)) {
				e.preventDefault();
			}
			if(window.innerWidth > breakPoint) return;
			const index = Array.from(this.trigger).indexOf(e.target);
			e.target.classList.toggle('active');
			this.content[index].classList.toggle('active');
		})
	}
}
