export class Mailmagazine {
	constructor() {
		this.wrapper = document.querySelector('.js-mailmagazine')
		if(!this.wrapper) return;
		this.trigger = this.wrapper.querySelector('.title')
		this.content = this.wrapper.querySelector('.content')

		this.trigger.addEventListener('click', (e) => {
			if(window.innerWidth >= 900) return
			e.preventDefault()
			this.wrapper.classList.toggle('-hide')
		}, {
			once: true
		})

		this.wrapper.addEventListener('mouseenter', () => {
			if(window.innerWidth < 900) return
			if(window.pageYOffset <= 0) return
			this.wrapper.classList.toggle('-hide')
		})

		this.wrapper.addEventListener('mouseleave', () => {
			if(window.innerWidth < 900) return
			if(window.pageYOffset <= 0) return
			this.wrapper.classList.toggle('-hide')
		})

		window.addEventListener('click', () => {
			const current = document.activeElement
			if(/input|textarea|select/.test(current.tagName.toLowerCase())) {
				this.wrapper.classList.add('-none')
			} else {
				this.wrapper.classList.remove('-none')
			}
		})

		// TODO: throttle導入
		window.addEventListener('scroll', () => {
			if(window.pageYOffset > 0) {
				this.wrapper.classList.add('-hide')
			} else {
				this.wrapper.classList.remove('-hide')
			}
		})
	}
}