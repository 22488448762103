export class Slider {
  constructor() {
		this.body = document.querySelector('body')
    this.slider = document.querySelector('.js-slider');
		if(!this.slider) return;
		this.prev = document.querySelector('.js-slider-prev')
		this.next = document.querySelector('.js-slider-next')
		this.items = this.slider.querySelectorAll('.item').length
		this.width = 232
		this.first = 0
		this.position = 0

		this.prev.style.display = 'none'

		this.prev.addEventListener('click', (e) => {
			e.preventDefault()
			if(this.first <= 0) {
				this.toggleArrow()
				return
			}
			this.first -= 1
			this.position = -this.first * this.width
			this.slider.style.transform = `translateX(${this.position}px)`
			this.toggleArrow()
		})

		this.next.addEventListener('click', (e) => {
			e.preventDefault()
			if(this.showNext()) {
				this.toggleArrow()
				return
			}
			this.first += 1
			this.position = -this.first * this.width
			this.slider.style.transform = `translateX(${this.position}px)`
			this.toggleArrow()
		})
  }

	toggleArrow() {
		if(this.first <= 0) {
			this.prev.style.display = 'none'
			this.next.style.display = 'block'
		} else if(this.showNext()) {
			this.prev.style.display = 'block'
			this.next.style.display = 'none'
		} else {
			this.prev.style.display = 'block'
			this.next.style.display = 'block'
		}
	}

	showNext() {
		return this.first >= this.items - Math.floor(this.body.offsetWidth / this.width)
	}
}