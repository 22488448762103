export class Contact {
  constructor() {
		this.checkbox = document.querySelector('.js-contact')
		if(!this.checkbox) return;
		this.trigger = document.querySelector('.js-contact-target')

		this.checkbox.addEventListener('change', (e) => {
			if(this.checkbox.checked) {
				this.trigger.disabled = false
			} else {
				this.trigger.disabled = true
			}
		})
  }
}
